import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CheckList = makeShortcode("CheckList");
const CheckListItem = makeShortcode("CheckListItem");
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av faner`}</h2>
    <p>{`Faner kan være nyttige for å dele opp innholdet på siden i logiske «blokker» og forenkle navigasjonen for brukerne.`}</p>
    <div className="sb1ds-layout-2col-grid">
    <div>
        <h3 className="ffe-h4">Gjør sånn:</h3>
        <CheckList mdxType="CheckList">
            <CheckListItem mdxType="CheckListItem">Bruk faner når brukerne skal bytte mellom ulike views i samme kontekst</CheckListItem>
            <CheckListItem mdxType="CheckListItem">Skriv korte og enkle fanenavn, helst ett til to ord</CheckListItem>
            <CheckListItem mdxType="CheckListItem">Plasser alltid rekken med faner øverst i toppen av innholdet</CheckListItem>
        </CheckList>
    </div>
    <div>
    <h3 className="ffe-h4">Ikke sånn:</h3>
        <CheckList mdxType="CheckList">
            <CheckListItem isCross={true} mdxType="CheckListItem">Gå for faner når brukerne trenger å se innhold fra flere av fanene samtidig, eller sammenligne innholdet</CheckListItem>
        </CheckList>
    </div>
    </div>
    <h2>{`Tab og TabGroup`}</h2>
    <p>{`For å implementere faner i løsningen bruker du komponentene `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Tab`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`TabGroup`}</code>{`. Dette er knapper med fane-funksjonalitet, som grupperer innholdet slik at én gruppe er synlig og resten skjult.`}</p>
    <p>{`Sånn ser en enkelt fane ut:`}</p>
    <DSExample name="tabs_Tab" mdxType="DSExample" />
    <p>{`Aktiv/valgt fane kan vises ved å sende inn `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selected`}</code>{`:`}</p>
    <DSExample name="tabs_Tab_selected" mdxType="DSExample" />
    <p>{`I tillegg kan du sende inn egendefinerte props for å legge til clickhandlere og andre attributter:`}</p>
    <DSExample name="tabs_Tab_customProps" mdxType="DSExample" />
    <p>{`Fanene skal alltid grupperes i en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`TabGroup`}</code>{`:`}</p>
    <DSExample name="tabs_TabGroup" mdxType="DSExample" />
    <p>{`Under ser du et eksempel hvor innhold er knyttet til hver fane.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-controls`}</code>{` tilsvarer `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`id`}</code>{` på elementet som fanen kontrollerer, og er en obligatorisk property som må sendes med i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Tab`}</code>{`. Se nærmere på eksempelet for hvordan denne brukes i praksis, til å knytte knappen til elementet som vises når knappen er selected.`}</p>
    <DSExample name="tabs_TabGroup_advanced" mdxType="DSExample" />
    <UUTestReport reportID="Tabs_Tabs" mdxType="UUTestReport" />
    <h3>{`noBreak`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`noBreak`}</code>{` er en property som kan sendes med til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`TabGroup`}</code>{`, dersom du ønsker å sikre at fanene ikke brekker over flere linjer.
Dette kan være nyttig i tilfeller der man har plass til alt på en linje, selv på f.eks mobil. `}</p>
    <DSExample name="tabs_TabGroup_noBreak" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      